/* eslint-disable import/prefer-default-export */
function shouldScrollTo(previousPathname, pathname) {
  if (!previousPathname.startsWith('/blog/') || !pathname.startsWith('/blog/')) {
    return true;
  }

  return pathname.split('/').length > 4;
}

exports.shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  if (shouldScrollTo(prevRouterProps ? prevRouterProps.location.pathname : '', location.pathname)) {
    window.scrollTo(...(currentPosition || [0, 0]));
  }

  return false;
};
